<template>
  <div class="page">
    <!-- banner图 -->
    <!-- 头部 -->
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :bannerImg="bannerImg" :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner1" src="@/assets/img/textSearch/banner-1.png" />
        <img class="banner2" src="@/assets/img/textSearch/banner-2.png" />
        <img class="banner3" src="@/assets/img/textSearch/banner-3.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" />
        <div class="similar_class">
          <div class="similar_clas1">
            <!-- 请输入商品文本 -->
            <div class="similar_text similar_sec">
              <p>请输入商品文本：<a @click="randomClicks">随机示例</a></p>
              <el-form
                :model="formUrls"
                class="similarClasses"
                :class="{ frosted_glasses: isActive }"
              >
                <el-form-item class="similarClass_1">
                  <el-input
                    type="textarea"
                    resize="none"
                    v-model="formUrls.titles"
                    placeholder="请输入完整英文商品标题/文本；"
                    class="similarInput"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item class="similarClasses_2">
                  <el-button type="primary" @click="onSubmit" class="similarBtn"
                    >开始处理</el-button
                  >
                </el-form-item>
              </el-form>
              <div class="msg-tips" style="display: none" id="msgTip">
                文本解析中...
              </div>
              <div class="msg-tips" style="display: none" id="msgTips">
                很抱歉，文本中可能存在敏感内容,请<span class="retry"
                  >重新输入</span
                >。
              </div>
            </div>

            <!-- 处理结果 -->
            <div class="similar_list similar_sec">
              <p>相似文案推荐：</p>
              <div class="similar_div">
                <div
                  v-for="(item, index) in imgList_2"
                  :key="index"
                  class="similar_1"
                >
                  <div class="similarImg" v-if="item.url">
                    <img v-if="item.url" :src="item.url" />
                  </div>
                  <div class="similarityDes">
                    {{ item.text }}
                    <div class="similarity" v-if="item.score">
                      相似度： <span>{{ item.score }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main"
          theme="light"
          :list="diffList"
          :img="diffImg"
        ></ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo class="application-scene_main" :img="appImg">
        <div slot="text">
          <div class="app-title">
            {{ appliList.title }}
          </div>
          <ul class="app-list">
            <li v-for="(item, index) in appliList.desc" :key="index">
              <img src="../../assets/img/imgSimilar/scene-3.png" alt="" />
              {{ item }}
            </li>
          </ul>
        </div>
      </ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';

import app14 from '@/assets/img/gongneng/wenbensou/changjing.png';

import defaultImage from '@/assets/img/goods_2.jpeg';

// 公用组件
import '../../assets/css/normalize.css';
import '../../assets/css/public.css';
import '../../assets/css/commonModule.css';

import { getvectortext } from '@/api/aidata.js';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';

export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: require('@/assets/img/textSearch/bg.png'),
      coreImg: require('@/assets/img/textSearch/core-1.png'),
      bigTitle_1: '文本<span style="color:#6C38E0">语义相似</span>',
      smallTitle_1: '基于自建商品库，根据商品标题/文本，寻找相似商品',

      // 核心功能
      spanList: [
        {
          title: '自定义商品库',
          desc: '支持自定义商品库/文本库，创建自己领域内的数据集。',
        },
        {
          title: '文本语义相似',
          desc: '目前支持3种语义算法，在长文本、短文本、词级别上都有较好的应用效果。支持输入文本与自建库文本逐一对比相似度。',
        },
      ],

      //   差异优势
      diffImg: require('@/assets/img/textSearch/diff-1.png'),
      diffList: [
        {
          title: '公有商品库',
          desc: '提供丰富的公有商品库，供查询热销品商品信息。',
        },
        {
          title: '支持千万级大图库',
          desc: '自建图库支持千万级图片量上传入库，实现实时检索，单图毫秒级响应。',
        },
        {
          title: '算法准确性高',
          desc: '基于大量电商训练数据，使用精准的算法迭代模型不断提高准确度。',
        },
        {
          title: '服务简单易用',
          desc: '标准化接口封装，提供丰富的HTTP SDK，接入简单，快速上手。',
        },
      ],

      //   应用场景
      appImg: require('@/assets/img/textSearch/app-1.png'),
      appliList: {
        title: '文本相似提升运营效率',
        desc: [
          '用户query语义搜索，结果集更精准。',
          '平台内标题相似商品推荐。',
          '寻找相似/同款商品进行比价、竞争程度分析。',
          '平台编辑换品检测等。',
        ],
      },
      formUrls: {
        titles:
          'HOT CLASSIC! women fashion England middle long trench coat/high quality brand design double breasted trench coat/cotton fabric size S-XXL 5 colors',
      },
      dataOffending: '',
      imgList_2: [
        {
          url: '',
          text: '',
        },
        {
          url: '',
          text: '',
        },
        {
          url: '',
          text: '',
        },
        {
          url: '',
          text: '',
        },
        {
          url: '',
          text: '',
        },
        {
          url: '',
          text: '',
        },
      ],
      isActive: false,
      randomList: [
        {
          keyds:
            'HOT CLASSIC! women fashion England middle long trench coat/high quality brand design double breasted trench coat/cotton fabric size S-XXL 5 colors',
        },
        {
          keyds:
            'lady Cat Eye Frame Sunglasses 40014 Acetate Fiber Frame Gold Metal Legs Luxury Brand Womens Personalized Multifunctional Solar Occhiali da Sole',
        },
        {
          keyds:
            'Disposable Black nitrile gloves powder free for Inspection Industrial Lab Home and Supermaket Comfortable Pink',
        },
        {
          keyds:
            'celebrity catwalk models straw bag latest design simple and practical Designers Womens Handbags Purses 2021 is specially designed for young girls Classic fashion',
        },
        {
          keyds:
            "Medusa Designer T Shirts classic style Top Summer Spring Brand Mens Clothing Men's Fabric Letters Polo T-shirts Lapel Collar Casual Women's Paris T Shirt Tees",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 开始处理
    async onSubmit() {
      // this.$message({
      //   message: '功能尚未开放',
      //   type: 'info',
      // });
      // return;
      if (this.formUrls.titles === '') {
        // return this.$message.error("请输入内容");
        this.$message({
          message: '请输入内容',
          type: 'error',
        });
        return;
      }
      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';
      let data = {
        text: this.formUrls.titles,
      };
      const { data: rest } = await getvectortext(data);
      console.log(rest);
      if (rest.state === '0x0000') {
        setTimeout(() => {
          this.isActive = false;
          document.getElementById('msgTip').style.display = 'none';
          this.imgList_2 = rest.data;
          this.imgList_2 = this.imgList_2.map((item) => {
            item.score = item.score.toFixed(2);
            return item;
          });
        }, 1000);
      } else if (rest.state === '0x0008') {
        // this.$message.error(rest.message);
        this.$message({
          message: rest.message,
          type: 'error',
        });
        this.$refs.navheader.logOn();
      } else {
        this.isActive = false;
        document.getElementById('msgTip').style.display = 'none';
        this.$message({
          message: rest.message,
          type: 'error',
        });
        // return this.$message.error(rest.message);
      }
    },

    // 默认图片
    setDefaultImage(e) {
      e.target.src = defaultImage;
    },

    // 随机示例
    randomClicks() {
      var index = Math.floor(Math.random() * 5);
      this.formUrls.titles = this.randomList[index].keyds;
    },
  },
};
</script>

<style lang="less" scoped>
/* header */
.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  img {
    position: absolute;
  }
  .banner1 {
    width: 19%;
    bottom: 27%;
    left: 7.2%;
  }
  .banner2 {
    width: 33.7%;
    bottom: 7%;
    left: 50%;
    transform: translateX(-52%);
  }
  .banner3 {
    width: 19.2%;
    bottom: 13.2%;
    right: 7.5%;
  }
  .forms {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 73.8%;
  }
}

/* 核心 */
.core-function {
  padding: 80px 0 120px;
  /deep/ .common-two-column_img {
    width: 32%;
    margin-top: -109px;
  }
  /deep/ .common-two-column_img {
    margin-right: 93px;
  }
  /deep/ .common-two-column {
    column-gap: 133px;
  }
}
.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  .similar-main {
    background: #f0f2f5;
    > div {
      width: 1280px;
      margin: auto;
    }
    .similar_class {
      width: 100%;
      background: #fff;
      border-radius: 16px;
      padding: 24px;
      box-sizing: border-box;
      margin-top: 40px;
      display: flex;
    }
    .similar_clas1 {
      display: flex;
    }
    .similar_sec {
      flex: 1;
      flex-shrink: 0;
      p {
        font-size: 16px;
        margin-bottom: 24px;
        a {
          color: #6c38e0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .similarInput {
      border: 1px solid #c5c5cb;
      border-radius: 8px;
      height: 434px;
      overflow: hidden;
      width: 374px;
      /deep/ .el-textarea__inner {
        height: 100%;
        width: 100%;
        color: #000108;
        line-height: 22px;
        padding: 16px 24px 16px 20px;
      }
    }
    .similarClasses_2 {
      margin-top: 48px;
      /deep/.el-form-item__content {
        display: flex;
        .similarBtn {
          margin-left: auto;
          padding: 13px 52px;
          font-size: 16px;
          font-weight: normal;
          background-color: #6c38e0;
        }
      }
    }
    .similar_list {
      border-left: 2px solid #f0f2f5;
      margin-left: 24px;
      padding-left: 24px;
    }
    .similar_1 {
      display: flex;
      .similarImg {
        flex-shrink: 0;
        width: 75px;
        height: 75px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 12px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .similar_div {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    .similarityDes {
      color: #000108;
    }
    .similarity {
      margin-top: 12px;
      font-family: PingFang SC;
      span {
        color: #6c38e0;
      }
    }
  }
}
/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
  .different-advantage {
    padding-top: 80px;
    padding-bottom: 131px;
  }
  /deep/ .common-two-column {
    column-gap: 225px;
  }
  /deep/ .common-two-column_img {
    width: 39.4%;
    margin-right: 85px;
    margin-top: -22px;
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 120px;
}

.application-scene_main {
  margin-top: 80px;
  .app-title {
    font-size: 24px;
    color: #000;
    border-radius: 157px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 64px;
      background: #ff8645;
      border-radius: 100px;
    }
  }
  .app-list {
    margin-top: 24px;
    row-gap: 24px;
    li {
      display: flex;
      align-items: center;
      color: #5d5f6c;
      font-size: 16px;
    }
    img {
      width: 22px;
      margin-right: 8px;
    }
  }
}
.application-scene /deep/ .common-two-column {
  column-gap: 34px;
}
.application-scene /deep/ .common-two-column_img {
  width: 40%;
  margin-left: 58px;
  margin-top: -132px;
}
@media screen and (max-width: 990px) {
  .similar-main-wrap .similar-main .similarInput {
    width: 35vw;
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    width: 1440px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
